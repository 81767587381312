import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Formik } from 'formik';
import { leadFormValidation } from '../util/validation';
import formService from '../util/form-service';
import { navigate } from 'gatsby';
import Spinner from './spinner';
import arrowRight from '../images/btn-arrow.svg';

const StyledLeadForm = styled.div`
  max-width: 24rem;
`;

const LeadForm = ({
  originWebApp,
  originWebPageType,
  originWebFormType,
  originWebFormCategory,
  originCampaign,
  dataExtension,
  buttonText,
  formLegal,
}) => {
  const [apiError, setApiError] = useState('');
  const [active, setActiveState] = useState({});

  const handleSubmit = (values, formikBag) => {
    setApiError('');
    formService
      .submitForm({
        ...values,
        originWebApp,
        originWebPageType,
        originWebFormType,
        originWebFormCategory,
        originCampaign,
        dataExtension,
      })
      .then(res => {
        console.log('SUCCESS!');
        console.log(res);
        navigate('/thank-you');
      })
      .catch(error => {
        console.log('FAILURE!');
        console.log(error);
        navigate('/thank-you');
        formikBag.setSubmitting(false);
        // setApiError(
        //   'There was an error processing your request. Please try again or contact support.'
        // );
      });
  };

  const setActive = e => {
    setActiveState({ ...active, [e.target.name]: true });
  };
  const setUnactive = e => {
    setActiveState({ ...active, [e.target.name]: false });
  };

  return (
    <StyledLeadForm>
      <Formik
        initialValues={{
          emailAddress: '',
          firstName: '',
          lastName: '',
          // zip: '',
          phoneNumber: '',
          canEmail: false,
        }}
        validationSchema={leadFormValidation}
        onSubmit={handleSubmit}
        render={({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => (
          <StyledForm onSubmit={handleSubmit} noValidate>
            <StyledFieldset disabled={isSubmitting} name="lead-form-fieldset">
              <Row>
                <Col>
                  <StyledFormGroup>
                    <StyledLabel
                      float={values.firstName || active.firstName}
                      htmlFor="firstName"
                    >
                      First Name
                    </StyledLabel>
                    <StyledInput
                      id="firstName"
                      name="firstName"
                      type="text"
                      valid={values.firstName && !errors.firstName}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        setUnactive(e);
                      }}
                      onFocus={setActive}
                      error={touched.firstName && errors.firstName}
                      size={10}
                    />

                    <StyledErrorMessage>
                      {touched.firstName && errors.firstName}
                    </StyledErrorMessage>
                  </StyledFormGroup>
                </Col>

                <Col>
                  <StyledFormGroup>
                    <StyledLabel
                      float={values.lastName || active.lastName}
                      htmlFor="lastName"
                    >
                      Last Name
                    </StyledLabel>
                    <StyledInput
                      id="lastName"
                      name="lastName"
                      type="text"
                      valid={values.lastName && !errors.lastName}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        setUnactive(e);
                      }}
                      onFocus={setActive}
                      error={touched.lastName && errors.lastName}
                      size={10}
                    />

                    <StyledErrorMessage>
                      {touched.lastName && errors.lastName}
                    </StyledErrorMessage>
                  </StyledFormGroup>
                </Col>
              </Row>

              <StyledFormGroup>
                <StyledLabel
                  float={values.emailAddress || active.emailAddress}
                  htmlFor="emailAddress"
                >
                  Email
                </StyledLabel>
                <StyledInput
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  valid={values.emailAddress && !errors.emailAddress}
                  value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    setUnactive(e);
                  }}
                  onFocus={setActive}
                  error={touched.emailAddress && errors.emailAddress}
                />

                <StyledErrorMessage>
                  {touched.emailAddress && errors.emailAddress}
                </StyledErrorMessage>
              </StyledFormGroup>

              {/*<StyledFormGroup>*/}
              {/*  <StyledLabel float={values.zip || active.zip} htmlFor="zip">*/}
              {/*    Zip*/}
              {/*  </StyledLabel>*/}
              {/*  <StyledInput*/}
              {/*    name="zip"*/}
              {/*    type="text"*/}
              {/*    valid={values.zip && !errors.zip}*/}
              {/*    value={values.zip}*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={e => {*/}
              {/*      handleBlur(e);*/}
              {/*      setUnactive(e);*/}
              {/*    }}*/}
              {/*    onFocus={setActive}*/}
              {/*    error={touched.zip && errors.zip}*/}
              {/*    size={10}*/}
              {/*  />*/}

              {/*  <StyledErrorMessage>*/}
              {/*    {touched.zip && errors.zip}*/}
              {/*  </StyledErrorMessage>*/}
              {/*</StyledFormGroup>*/}

              <StyledFormGroup>
                <StyledLabel
                  float={values.phoneNumber || active.phoneNumber}
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </StyledLabel>
                <StyledInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  valid={values.phoneNumber && !errors.phoneNumber}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    setUnactive(e);
                  }}
                  onFocus={setActive}
                  error={touched.phoneNumber && errors.phoneNumber}
                />

                <StyledErrorMessage>
                  {touched.phoneNumber && errors.phoneNumber}
                </StyledErrorMessage>
              </StyledFormGroup>

              <div className="form-legal">
                <input
                  type="checkbox"
                  id="canEmail"
                  name="canEmail"
                  checked={values.canEmail}
                  onBlur={e => {
                    handleBlur(e);
                    setUnactive(e);
                  }}
                  onChange={handleChange}
                  onFocus={setActive}
                />
                <StyledLabel
                  className="form-legal-label"
                  htmlFor="canEmail"
                  dangerouslySetInnerHTML={{
                    __html: formLegal,
                  }}
                />
                <StyledErrorMessage>
                  {touched.canEmail && errors.canEmail}
                </StyledErrorMessage>
              </div>
              {apiError && <ApiError>{apiError}</ApiError>}
              <StyledButton
                type="submit"
                // disabled={
                //   Object.entries(touched).filter(t => t).length && !isValid
                // }
              >
                {buttonText || 'Submit'}
                {!isSubmitting ? <img src={arrowRight} alt="" /> : <Spinner />}
              </StyledButton>
            </StyledFieldset>
          </StyledForm>
        )}
      />
    </StyledLeadForm>
  );
};

LeadForm.propTypes = {
  originWebApp: PropTypes.string.isRequired,
  originWebPageType: PropTypes.string.isRequired,
  originWebFormType: PropTypes.string.isRequired,
  originWebFormCategory: PropTypes.string.isRequired,
  originCampaign: PropTypes.string.isRequired,
  dataExtension: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  formLegal: PropTypes.string.isRequired,
};

export default LeadForm;

/*******************************
 * UTILITY
 *******************************/

const StyledForm = styled.form`
  .form-legal {
    position: relative;
    padding-bottom: 0.5rem;
    //margin: 0 0 2.5rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 0.75rem;
    line-height: 1.5;
    letter-spacing: -0.3px;
    color: white;
    display: flex;
    input[type='checkbox'] {
      font-size: 2rem;
      flex-basis: 2rem;
      margin: 0.25rem 0.5rem 0 0;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }
`;

const StyledFieldset = styled.fieldset`
  border: none;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  margin: 0 -0.5em;
`;

const Col = styled.div`
  flex: 1;
  margin: 0 0.5rem;
`;

const StyledFormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  line-height: 1;
`;

const StyledInput = styled.input`
  margin: 0;
  padding: 0.375rem;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1rem;
  line-height: 1.375;
  color: ${props => props.theme.colors.grayMedium};
  border: 2px solid
    ${props => (props.error ? props.theme.colors.danger : 'white')};
  border-radius: ${props => props.theme.borders.radius};
  outline: none;
  background: #ffffff none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  transition: all 200ms ease-in;

  ${props =>
    props.valid &&
    css`
      border-color: ${props => props.theme.colors.secondary};
      border-width: 1.5px;
    `};

  &:focus {
    border-color: ${props => props.theme.colors.secondary};
    border-width: 1.5px;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

const StyledErrorMessage = styled.div`
  position: absolute;
  //left: 0.25rem;
  bottom: -1rem;
  padding-left: 0.25rem;
  width: 100%;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.75rem;
  line-height: 1.5;
  background-color: ${props => props.theme.colors.danger};
  color: white;
  font-weight: 600;
`;

const StyledButton = styled.button`
  margin: 1.5rem 0;
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.0625rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 200ms ease-in;
  opacity: 1;

  img {
    margin-left: 2rem;
    transition: all 200ms ease-in;
  }

  &:enabled:hover {
    background-color: ${props => props.theme.colors.secondary};
    img {
      transform: translateX(-15%);
    }
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ApiError = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.colors.danger};
  color: white;
  font-size: 0.875rem;
  opacity: 0.9;
`;
