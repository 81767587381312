import * as Yup from 'yup';

export const regex = {
  zip: /^\d{5}$/,
  phoneNumber: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const canEmail = Yup.bool()
  .required()
  .oneOf([true], 'Please read and agree to the terms and conditions.');

export const emailAddress = Yup.string()
  .required('Email address is required.')
  .email('Please enter a valid email address.');

export const firstName = Yup.string().required('First name is required.');

export const lastName = Yup.string().required('Last name is required.');

export const zip = Yup.string()
  .required('Zip code is required.')
  .matches(regex.zip, 'Please enter a valid 5 digit zip code.');

export const phoneNumber = Yup.string()
  .required('Phone number is required.')
  .matches(regex.phoneNumber, 'Please enter a valid 10 digit phone number.');

export const preferredAppointmentDate = Yup.string().required(
  'Preferred appointment date is required.'
);

export const preferredAppointmentTime = Yup.string().required(
  'Preferred appointment time is required.'
);

export const marketingFormValidation = Yup.object().shape({
  emailAddress,
});

export const leadFormValidation = Yup.object().shape({
  emailAddress,
  firstName,
  lastName,
  // zip,
  phoneNumber,
  canEmail,
});

export const appointmentFormValidation = Yup.object().shape({
  emailAddress,
  firstName,
  lastName,
  zip,
  phoneNumber,
  preferredAppointmentDate,
  preferredAppointmentTime,
});
