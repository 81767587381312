import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';

const StyledAboutSection = styled.div`
  color: ${props => props.theme.colors.grayMedium};
  .title {
    font-size: 1.75rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }
  .description {
    font-size: 1.125rem;
    line-height: 1.75;
  }
  .right-section {
    margin: 3rem 0;
    padding: 0 0 0 2rem;
    border-left: 1px solid #ddd;

    ${MEDIA.TABLET`
      border: none;
      padding: 0
    `}
  }
  .address,
  .hours {
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: 2rem;
    h6 {
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: ${props => props.theme.colors.primary};
    }
  }
  .phone-number {
    font-weight: bold;
  }
`;

const AboutSection = ({
  title,
  description,
  name,
  address,
  city,
  state,
  zip,
  phone,
  hoursWeekday,
  hoursMonday,
  hoursSaturday,
  hoursSunday,
}) => {
  return (
    <StyledAboutSection>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7">
            <h3 className="title">{title}</h3>
            <p className="description">{description}</p>
          </div>
          <div className="col-lg-5">
            <div className="right-section">
              <div className="row">
                <div className="col-sm-7">
                  <div className="address">
                    <h6>Address</h6>
                    <p>
                      {name} <br />
                      {address} <br />
                      {city}, {state} {zip}
                    </p>
                    <p className="phone-number">{phone}</p>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="hours">
                    <h6>Hours</h6>
                    <p>
                      Mon: {hoursMonday} <br />
                      Tue-Fri: {hoursWeekday} <br />
                      Sat: {hoursSaturday} <br />
                      Sun: {hoursSunday} <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAboutSection>
  );
};

AboutSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hoursWeekday: PropTypes.string.isRequired,
  hoursMonday: PropTypes.string.isRequired,
  hoursSaturday: PropTypes.string.isRequired,
  hoursSunday: PropTypes.string.isRequired,
};

export default React.memo(AboutSection);
