import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';

const StyleGallerySection = styled.div`
  margin-bottom: 4rem;

  .gallery-card {
    margin: 3rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border: 1px solid ${props => props.theme.colors.grayLightest};

    ${MEDIA.DESKTOP`
      margin: 1.5rem;
    `}
    .gallery-img {
      width: 100%;
    }
  }
`;

const GallerySection = ({ images }) => {
  return (
    <StyleGallerySection>
      <div className="container">
        <div className="row justify-content-around">
          {images &&
            images.map(image => (
              <div key={image.title} className="col-md-6">
                <div className="gallery-card">
                  <img
                    className="gallery-img"
                    src={image.src}
                    alt={image.title}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </StyleGallerySection>
  );
};

GallerySection.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(GallerySection);
