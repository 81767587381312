/*******************************
 * COLORS
 *******************************/
export const colors = {
  primary: '#244C5A',
  secondary: '#A0D1CA',
  // secondaryLightBg: 'rgba(0,117,201,.3)',
  // lightBlue: '#00a0dd',
  danger: '#CD545B',
  warning: '#DBDE70',
  // grayDark: '#414042',
  grayMedium: '#5B6770',
  // grayLight: '#a2a2a3',
  // grayLighter: '#d6d6d6',
  grayLightest: '#eeeeee',
};

/*******************************
 * FONTS
 *******************************/
export const fonts = {
  // Default font for the body
  primary:
    '-apple-system, BlinkMacSystemFont,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol", sans-serif',
  // Default font for headers
  secondary:
    '-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol", sans-serif',
};

/*******************************
 * Borders
 *******************************/
export const borders = {
  radius: '0',
  color: colors.grayLighter,
};

export default {
  colors,
  fonts,
  borders,
};
