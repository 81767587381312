import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import '../../node_modules/bootstrap/dist/css/bootstrap-reboot.css';
import '../../node_modules/bootstrap/dist/css/bootstrap-grid.css';

const StyledLayout = styled.div``;

const Layout = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout>{children}</StyledLayout>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export default React.memo(Layout);
