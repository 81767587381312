import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWhyBuySection = styled.div`
  color: ${props => props.theme.colors.grayMedium};
  .title {
    font-size: 1.75rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 2rem;
    text-align: center;
  }
  .why-buy-card {
    text-align: center;
    line-height: 1.75;
    img {
      max-width: 8rem;
      margin-bottom: 1rem;
    }
    .card-title {
      font-size: 1.125rem;
      color: ${props => props.theme.colors.primary};
      margin-bottom: 0.5rem;
    }
    .card-description {
      font-size: 1.0625rem;
    }
  }
`;

const WhyBuySection = ({
  title,
  firstIcon,
  firstTitle,
  firstDescription,
  secondIcon,
  secondTitle,
  secondDescription,
  thirdIcon,
  thirdTitle,
  thirdDescription,
}) => {
  return (
    <StyledWhyBuySection>
      <div className="container">
        <h3 className="title">{title}</h3>
        <div className="row my-5">
          <div className="col-md-4">
            <div className="why-buy-card">
              <img src={firstIcon} alt={firstTitle} className="icon" />
              <h5 className="card-title">{firstTitle}</h5>
              <p className="card-description">{firstDescription}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-buy-card">
              <img src={secondIcon} alt={secondTitle} className="icon" />
              <h5 className="card-title">{secondTitle}</h5>
              <p className="card-description">{secondDescription}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="why-buy-card">
              <img src={thirdIcon} alt={thirdTitle} className="icon" />
              <h5 className="card-title">{thirdTitle}</h5>
              <p className="card-description">{thirdDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </StyledWhyBuySection>
  );
};

WhyBuySection.propTypes = {
  title: PropTypes.string.isRequired,
  firstIcon: PropTypes.string.isRequired,
  firstTitle: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondIcon: PropTypes.string.isRequired,
  secondTitle: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  thirdIcon: PropTypes.string.isRequired,
  thirdTitle: PropTypes.string.isRequired,
  thirdDescription: PropTypes.string.isRequired,
};

export default React.memo(WhyBuySection);
