import axios from 'axios';
import { withPrefix } from 'gatsby';

/**
 *
 * @returns {Promise<AxiosResponse<String>>}
 */
export const getFormSubmissionUrl = () => {
  return axios
    .get(withPrefix('/appSettings.json'))
    .then(({ data: { formSubmissionUrl } }) => {
      if (!formSubmissionUrl) {
        throw new Error('NO FORM SUBMISSION URL RECEIVED');
      }
      return formSubmissionUrl;
    })
    .catch(err => {
      throw new Error(err);
    });
};

export const submitForm = formObj => {
  const webUrl = getWebUrl();
  const utmParams = getUTMParameters();
  const gaClientId = getGAClientId();

  // Modify form model to support submission directly to Marketing Cloud
  // emailAddress -> email
  const formModel = {
    _clientID: '1084389',
    _deExternalKey: 'FormActivityBirmingham',
    _action: 'add',
    _returnXML: 0,
    _successURL: 'https://www.claytonhomes.com/',
    _errorURL: 'https://www.claytonhomes.com/privacy',
    email: formObj.emailAddress,
    firstName: formObj.firstName || null,
    lastName: formObj.lastName || null,
    city: formObj.city || null,
    stateProvince: formObj.stateProvince || null,
    zip: formObj.zip || null,
    phone: formObj.phoneNumber || null,
    canEmail: formObj.canEmail || true,
    doNotCall: formObj.doNotCall || false,
    leadComments: formObj.leadComments || null,
    preferredAppointmentDate: formObj.preferredAppointmentDate || null,
    preferredAppointmentTime: formObj.preferredAppointmentTime || null,
    originWebURL: webUrl,
    originWebApp: formObj.originWebApp || null,
    originWebPageType: formObj.originWebPageType || null,
    originWebFormType: formObj.originWebFormType || null,
    originWebFormCategory: formObj.originWebFormCategory || null,
    originCampaign: formObj.originCampaign || null,
    dataExtension: formObj.dataExtension || null,
    utmCampaign: utmParams.utm_campaign,
    utmSource: utmParams.utm_source,
    utmMedium: utmParams.utm_medium,
    utmTerm: utmParams.utm_term,
    utmContent: utmParams.utm_content,
    kenshooId: utmParams.kenshooid,
    gaClientId: gaClientId,
  };

  const formData = new FormData();

  Object.keys(formModel).forEach(key => {
    if (
      formModel[key] !== null &&
      formModel[key] !== '' &&
      formModel[key] !== undefined
    ) {
      formData.append(key, formModel[key]);
    }
  });

  return getFormSubmissionUrl().then(url => {
    if (!url) {
      let msg = 'ERROR RETRIEVING FORM SUBMISSION URL';
      console.error(msg, url);
      throw new Error(msg);
    }
    return axios.post(url, formData, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data',
      },
    });
    // .catch(err => {
    //   console.log(err);
    //   throw new Error(err);
    // });
  });
};

// Export public functions
export default {
  submitForm,
};

/*******************************
 * UTILITIES
 *******************************/
export const getWebUrl = () =>
  typeof window !== undefined ? window.location.href : null;

export const getGAClientId = () => {
  try {
    const tracker = window.ga.getAll()[0];
    return tracker.get('clientId');
  } catch (e) {
    return null;
  }
};

export const getUTMParameters = () => {
  const params = getQueryParams();
  return {
    utm_campaign: params['utm_campaign'] || null,
    utm_source: params['utm_source'] || null,
    utm_medium: params['utm_medium'] || null,
    utm_term: params['utm_term'] || null,
    utm_content: params['utm_content'] || null,
    kenshooid: params['kenshooid'] || null,
  };
};

/**
 * Grabs the search string from the url and returns
 * an Object of all the parameters
 * @return {Object} queryParams
 */
export const getQueryParams = () => {
  try {
    const query = window.location.search;
    return formatQueryParams(query);
  } catch (e) {
    return {};
  }
};

/**
 * Takes a search string and returns object of query parameters
 * Always returns an object
 * @param {string} searchString
 * @return {Object}
 */
export const formatQueryParams = searchString => {
  if (
    !searchString ||
    typeof searchString !== 'string' ||
    !searchString.length
  ) {
    return {};
  }

  if (searchString[0] !== '?') {
    return {};
  }

  return searchString
    .substr(1)
    .split('&')
    .map(pair => {
      let keyValue = pair.split('=');
      if (keyValue.length === 2) {
        return {
          [keyValue[0]]: decodeURIComponent(keyValue[1]),
        };
      }
      return {};
    })
    .reduce((obj, value) => ({ ...obj, ...value }), {});
};
