import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';

const StyledHero = styled.div`
  position: relative;

  background: url(${props => props.bgImage}) 50% 50% no-repeat;
  background-size: cover;

  ${MEDIA.TABLET`
     background: none;
  `}

  .bg-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-color: ${props => props.theme.colors.primary};
    opacity: 0.9;

    ${MEDIA.TABLET`
       width: 100%;
    `}
  }

  .content {
    color: white;
    padding: 2rem 0;

    .headline {
      line-height: 1.375;
      //letter-spacing: -1px;
    }
    .sub-headline {
      font-size: 1.0625rem;
      margin-bottom: 1.5rem;
    }
  }

  .mobile-image {
    position: relative;
    display: none;
    img {
      width: 100%;
    }
    ${MEDIA.TABLET`
       display: block;
    `}
  }
`;

const Hero = ({ children, heroImage, headline, subHeadline }) => {
  return (
    <StyledHero bgImage={heroImage} id="hero">
      <div className="bg-panel" />
      <div className="mobile-image">
        <img src={heroImage} alt="Kitchen" />
      </div>
      <div className="container">
        <div className="col-md-6 content pr-md-5 py-md-5">
          <h1 className="headline">{headline}</h1>
          <p className="sub-headline">{subHeadline}</p>
          <div className="children">{children}</div>
        </div>
      </div>
    </StyledHero>
  );
};

Hero.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string.isRequired,
  heroImage: PropTypes.string.isRequired,
};

Hero.defaultProps = {};

export default React.memo(Hero);
