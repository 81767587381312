import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleCta = styled.div`
  padding: 4rem 1.5rem;
  background-color: ${props => props.theme.colors.grayLightest};
  color: ${props => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    text-align: center;
    font-size: 1.5rem;
  }

  .button-cta {
    margin: 1.5rem 0;
    padding: 0.75rem 2rem;
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};
    font-size: 1.125rem;
    text-decoration: none;
    font-weight: 500;
    transition: 400ms ease-in;

    &:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      transform: translateY(-2%);
    }
  }
`;

const SimpleCta = ({ title, buttonLink, buttonText }) => {
  return (
    <StyledSimpleCta>
      <h2 className="title">{title}</h2>
      <a className="button-cta" href={buttonLink}>
        {buttonText}
      </a>
    </StyledSimpleCta>
  );
};

SimpleCta.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default React.memo(SimpleCta);
