import React from 'react';
import styled from 'styled-components';
import MEDIA from '../styles/media';

const StyledFooter = styled.div`
  padding: 1.5rem;
  text-align: center;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  font-size: 0.75rem;

  .copyright {
    margin-right: 1rem;

    ${MEDIA.PHONE`
      display: block;
      width: 100%;
    `}
  }

  .legal,
  .privacy {
    color: white;
    text-decoration: underline;
    margin: 0 0.5rem;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <span className="copyright">
        © {new Date().getFullYear()} CMH Services, Inc. All rights reserved
      </span>
      <a
        href="https://www.claytonhomes.com/legal"
        target="_blank"
        rel="noopener noreferrer"
        className="legal"
      >
        Legal
      </a>
      {' | '}
      <a
        href="https://www.claytonhomes.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
        className="privacy"
      >
        Privacy
      </a>
      {' | '}
      <a
        href="https://www.claytonhomes.com/privacy/ccpa/ca-resident-privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        className="privacy"
      >
        Privacy Policy for California Residents
      </a>
    </StyledFooter>
  );
};

export default React.memo(Footer);
