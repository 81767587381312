import { css } from 'styled-components';

export const BREAKPOINTS = {
  DESKTOP: 992,
  TABLET: 767,
  PHONE: 576,
};

export const MEDIA = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default MEDIA;
