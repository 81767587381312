import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import whitePhone from '../images/white-phone.png';
import MEDIA from '../styles/media';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    margin-left: 1.5rem;

    ${MEDIA.PHONE`
      margin-left: 1rem;
    `}
  }
  .phone {
    height: 6rem;
    padding: 0 1.5rem;
    background-color: ${props => props.theme.colors.primary};
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;

    ${MEDIA.PHONE`
      padding: 0 1rem;
    `}

    .phone-icon {
      width: 1rem;
      margin-right: 0.75rem;
      ${MEDIA.PHONE`
        margin-right: .5rem;
      `}
    }

    .phone-link {
      color: white;
      font-size: 1.25rem;
      text-decoration: none;

      ${MEDIA.PHONE`
        font-size: 1rem;
      `}
    }
  }
`;

const Header = ({ siteTitle, phoneNumber, logo }) => (
  <StyledHeader>
    <img className="logo" src={logo} alt={siteTitle} />
    <div className="phone">
      <a href={`tel:${phoneNumber}`} className="phone-link">
        <img src={whitePhone} alt="phone icon" className="phone-icon" />
        {phoneNumber}
      </a>
    </div>
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default React.memo(Header);
