import React from 'react';
import AboutSection from '../components/about-section';
import Footer from '../components/footer';
import GallerySection from '../components/gallery-section';
import Header from '../components/header';
import Hero from '../components/hero';
import Layout from '../components/layout';
import LeadForm from '../components/lead-form';
import SEO from '../components/seo';
import SimpleCta from '../components/simple-cta';
import WhyBuySection from '../components/why-buy-section';
import iconAffordable from '../images/affordable.svg';
import bgKitchen from '../images/bg-kitchen.jpg';
import iconCustomerService from '../images/customer-service.svg';
import img1 from '../images/gallery/1.jpg';
import img2 from '../images/gallery/2.jpg';
import img3 from '../images/gallery/3.jpg';
import img4 from '../images/gallery/4.jpg';
import img5 from '../images/gallery/5.jpg';
import img6 from '../images/gallery/6.jpg';
import img7 from '../images/gallery/7.jpg';
import img8 from '../images/gallery/8.jpg';
import scoutLogo from '../images/mh-scout-logo.png';
import iconTools from '../images/tools.svg';
// Data imports
import theme from '../styles/theme';

// Create centralized data object to use for template
export const data = {
  siteTitle: 'Community Scout',
  siteLogo: scoutLogo,
  phoneNumber: '(888) 754-6821',
  hero: {
    heroImage: bgKitchen,
    headline: 'Stop dreaming. Your new home is waiting.',
    subHeadline:
      'Are you ready to start the journey to your new home? Fill out the form to have a representative contact you about preowned and new homes for sale or rent in the Hidden Valley Community.',
  },
  form: {
    originWebApp: 'community-scout-landing-page',
    originWebPageType: 'landing-page',
    originWebFormType: 'lead-form',
    originWebFormCategory: '',
    originCampaign: 'community-scout-2019',
    dataExtension: 'FormActivityBirmingham',
    buttonText: 'Find Your Home Today',
    formLegal:
      'By checking this box I accept & agree to the CMH Services <a target="_blank" rel="noopener nofollow" href="https://www.claytonhomes.com/privacy">Privacy Practices</a> for this website & understand that my contact information will be used to contact me by telephone and to provide me with marketing communications about CMH Services and third party products and services.',
  },
  about: {
    title: 'About the Hidden Valley Community',
    description:
      'At Hidden Valley, our goal is to help you find the right home for your lifestyle. Located in the historic city of Birmingham, Alabama, our mobile home park offers many amenities like a sparkling community pool, gorgeous landscapes with ample shade trees, on-site management and an environment filled with southern hospitality.',
    name: 'Hidden Valley Community',
    address: '2802 Potts Hollow Rd.',
    city: 'Birmingham',
    state: 'AL',
    zip: '35215',
    phone: '(205) 683-9590',
    hoursMonday: '9am to 4pm',
    hoursWeekday: '9am to 6pm',
    hoursSaturday: '9am to 5pm',
    hoursSunday: 'Closed',
  },
  whyBuy: {
    title: 'Why Hidden Valley?',
    firstIcon: iconAffordable,
    firstTitle: 'Affordable Style',
    firstDescription:
      'With move-in ready, new and preowned manufactured homes for rent and for sale, we can find the perfect home to fit your lifestyle and budget.',
    secondIcon: iconCustomerService,
    secondTitle: 'Community Life',
    secondDescription:
      'Spacious yards, a stunning pool and, even more importantly, a peaceful atmosphere. You’ll find all this and more at Hidden Valley.',
    thirdIcon: iconTools,
    thirdTitle: 'Friendly Service',
    thirdDescription:
      'Our on-site management team is ready to answer any questions you may have about community life. We can’t wait to welcome you as our new neighbor!',
  },
  gallery: {
    images: [
      { src: img1, title: 'image 1' },
      { src: img2, title: 'image 2' },
      { src: img3, title: 'image 3' },
      { src: img4, title: 'image 4' },
      { src: img5, title: 'image 5' },
      { src: img6, title: 'image 6' },
      { src: img7, title: 'image 7' },
      { src: img8, title: 'image 8' },
    ],
  },
  simpleCta: {
    title: 'Are you ready to start the journey to your new home?',
    buttonText: 'Find Your Home Today',
    buttonLink: '#hero',
  },
  //   city: {
  //     title: 'Things We Love About Birmingham',
  //     description: `The City of Birmingham, Alabama, is a municipal corporation under the laws of the State of Alabama. Birmingham is the largest city in the state with a population currently estimated at 212,237, and a metro population of 1,136,650.
  //
  // Nestled at the foothills of the Appalachian Mountains at the cross-section of two major railroads, the city was once the primary industrial center of the southern United States. At the height of the nation’s manufacturing age, the city grew so fast in population, it was called the “Magic City.” Today, Birmingham has transformed itself into a medical research, banking and service-based economy, making it one of the nation’s most livable cities with a vibrant downtown, a burgeoning loft community, a world-class culinary scene and more green space per capita than any other city in the nation!
  //
  // Birmingham also boasts 99 historic neighborhoods and is often referred to as the cradle of the American Civil Rights Movement. The average (high) temperature in the city is 65°; the average low is 51°. The city is centrally located in the southeast and easily accessible to major hubs in the region`,
  //   },
};

const IndexPage = () => (
  <Layout theme={theme}>
    <SEO title="Community Scout" />
    <Header
      siteTitle={data.siteTitle}
      phoneNumber={data.phoneNumber}
      logo={data.siteLogo}
    />
    <Hero
      headline={data.hero.headline}
      subHeadline={data.hero.subHeadline}
      heroImage={data.hero.heroImage}
    >
      <LeadForm {...data.form} />
    </Hero>
    <AboutSection {...data.about} />
    <WhyBuySection {...data.whyBuy} />
    <GallerySection {...data.gallery} />
    <SimpleCta {...data.simpleCta} />
    <Footer />
  </Layout>
);

export default IndexPage;
